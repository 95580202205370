import React from "react"
import CompassImg from "../imageComponents/CompassImg"

export default function ClinicalSupervision() {
  return (
    <>
      <div className="therapy-flex">
        <div className="text-container">
          <h2>Clinical Supervision</h2>
          <p>
            Sarah is a registered supervisor with AHPRA and has been supervising
            other psychologists for over nine years.
          </p>
          <p>
            She enjoys supervising psychologists at a variety of different
            stages in their career. She has worked as a Clinical Supervisor on
            the UTS Master of Clinical Psychology programme. Sarah has also
            supported many psychologists in successfully completing their
            endorsement programme.
          </p>
          <p>
            Sarah focuses on developing a trusting and collaborative
            relationship with her supervisees and gains great satisfaction
            watching their growth over time.
          </p>
        </div>

        <div className="image-padding">
          <div className="complementary-image-wrapper">
            <CompassImg style={{}} className={"complementary-image"} />
            <p className="block-quote">
              <em>"We don’t have to do it alone. We were never meant to."</em>
              <br />
              <br />
              Bren&eacute; Brown
            </p>
          </div>
        </div>
      </div>
      <div className="standout-box">
        <h2>Areas outside my core expertise</h2>

        <ul className="hanging-indent-list">
          <li>
            <i className="fa fa-chevron-right"></i>
            Eating disorders
          </li>
          <li>
            <i className="fa fa-chevron-right"></i>
            Legal disputes around child custody
          </li>
          <li>
            <i className="fa fa-chevron-right"></i>
            Court assessments
          </li>
          <li>
            <i className="fa fa-chevron-right"></i>
            Domestic violence
          </li>
          <li>
            <i className="fa fa-chevron-right"></i>
            Acute psychosis
          </li>
        </ul>

        <p>
          If you need help with any of the above, please seek advice from a
          specialist service as they fall outside my area of expertise.
        </p>
      </div>
    </>
  )
}
