import React from "react"
import { Link } from "gatsby"
import KiteBoyImg from "../imageComponents/KiteBoyImg"

export default function IndividualTherapy() {
  return (
    <>
      <div className="therapy-flex">
        <div className="text-container">
          <h2>Individual Therapy</h2>
          <p>
            Sarah helps adults with a range of concerns such as depression,
            anxiety and trauma, as well as individuals with relationship difficulties.
          </p>
          <p>
            Following an initial assessment session, Sarah will explore and
            discuss with you which therapeutic approach will be the best fit for you.
          </p>
          <p>
            She uses a number of approaches including Cognitive
            Behavioural Therapy, Schema Therapy, Dialectical Behavioural
            Therapy, mindfulness and Compassion Focused Therapy.
          </p>
          <p>
            To find out more, please visit the <Link to="/approach">approach page</Link>.
          </p>
          <p>
            Sarah offers both short term and long term therapy depending on your
            particular needs.
          </p>
        </div>

        <div className="image-padding">
          <div className="complementary-image-wrapper">
            <KiteBoyImg style={{}} className={"complementary-image"} />
            <p className="block-quote">
              <em>
                “The growth of your own identity as a worthwhile person is, is
                basically . . . the biggest thing . . . thinking that you are a
                person who has got the right to do stuff and you have got stuff
                to contribute.”
              </em>
              <br />
              <br />
              Trauma survivor (Hartley et al, 2016).
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
