import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

export const query = graphql`
  {
    fluidImage: file(name: {eq: "love-and-relationship-concept-happy-couple-walking-C5MF7HA"}) {
      childImageSharp {
        fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function HappyCoupleImg({style, className}) {
    const { fluidImage: { childImageSharp: { fluid: image }}} = useStaticQuery(query);
  return (
    <div className={className}>
      <Image 
        fluid={image}
        style={style}
      />
    </div>
  )
}