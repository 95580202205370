import React from "react"
import Navigation from "../components/Navigation"
import CottesloeBGImage from "../components/CottesloeBGImage"
import BlueBoatHouseBGImage from "../components/BlueBoathouseBGImage"
import IndividualTherapy from "../components/content/IndividualTherapy"
import CouplesTherapy from "../components/content/CouplesTherapy"
import ClinicalSupervision from "../components/content/ClinicalSupervision"
import Layout from "../components/Layout"

export default () => (
  <Layout>
    <BlueBoatHouseBGImage
      style={{
      }}
    >
      <div className="hero-image-canvas"></div>
    </BlueBoatHouseBGImage>

    <div className="contents-wrapper">
      <div className="text-container">
        <h1>Services</h1>
      </div>
      <div className="text-container">
        <p>
          Sarah is a qualified Clinical Psychologist. She is registered with the Australian Health
          Practitioner Regulation Agency (AHPRA) and is a member of the Australian
          Psychology Society (APS).
        </p>
        <p>
          She is a registered Medicare provider and can provide rebated sessions
          under the Better Access initiative for clients with an eligible Mental
          Health Care Plan.
        </p>
      </div>
      <div className="text-container">
        <IndividualTherapy />
        <CouplesTherapy />
        <ClinicalSupervision />
      </div>
    </div>
  </Layout>
)
