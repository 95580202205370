import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

export const query = graphql`
  {
    fluidImage: file(name: {eq: "old-fashioned-brass-compass-on-rustic-wooden-table-P8ZNY3N"}) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function CompassImg({ style, className }) {
    const { fluidImage: { childImageSharp: { fluid: image }}} = useStaticQuery(query);
  return (
    <div className={className}>
      <Image
        fluid={image}
        style={style}
      />
    </div>
  )
}