import React from "react"
import HappyCoupleImg from "../imageComponents/HappyCoupleImg"
import KiteBoyImg from "../imageComponents/KiteBoyImg"

export default function () {
  return (
    <>
      <div className="therapy-flex">
        <div className="image-padding">
          <div className="complementary-image-wrapper">
            <div>
              <HappyCoupleImg style={{}} className={"complementary-image"} />
              <p className="block-quote">
                <em>
                  "Emotional dependency is not immature or pathological; it is
                  our greatest strength."
                  <br />
                  <br />
                </em>
                Sue Johnson, <em>Hold Me Tight</em>
              </p>
            </div>
          </div>
        </div>

        <div className="text-container">
          <h2>Couples Therapy</h2>

          <p>
            Over the last few years Sarah has developed a keen interest in
            working with couples.
          </p>
          <p>
            If your relationship is in a state of conflict, or causes you
            distress, if it is lacking in emotional closeness, or has been
            affected by infidelity or other stressors, please get in touch to
            explore how she can help you.
          </p>
          <p>
            Sarah uses Emotionally Focused Therapy because there is a strong evidence base demonstrating that therapy leads to an improvement in relationship satisfaction which can actually increase over the longer term, even after therapy has finished.
          </p>
        </div>
      </div>
    </>
  )
}

{
  /*

“For all of us, the person we love most in the world, the one
who can send us soaring joyfully into space, is also the person
who can send us crashing back to earth. All it takes is a slight
turning away of the head or a flip, careless remark. There is no
closeness without this sensitivity. If our connection with our
mate is safe and strong, we can deal with these moments of
sensitivity. Indeed, we can use them to bring our partner even
closer. But when we don’t feel safe and connected, these moments
are like a spark in a tinder forest. They set fire to the whole
relationship.” Sue Johnson (Hold Me Tight)


  */
}
